<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div>
      <el-row style="margin: 10px 0px 30px 0px;">
        <el-col :span="7">
          <el-popover placement="right" ref="newRecordPopover" :title="'New Pro Record'" width="300" trigger="click" @after-enter="handleNewRecordEnter">
            <el-form size="mini" id="addForm" :model="addForm" :inline="true" ref="addForm" :rules="rules" label-position="left" label-width="180px" v-loading="isPosting"
              element-loading-spinner="atom-audit-loader">
              <el-form-item prop="patientNumber" label="New Patient Number">
                <input id="newPatientNumber" style="height: 22px;" class="hia-el-input el-input__inner" type="addReview" v-model="addForm.patientNumber" maxlength="12" />
              </el-form-item>

              <el-form-item v-if="!this.project.clientId" label="Facility" prop="clientId">
                <hia-el-select v-model="addForm.clientId" placeholder="Facility" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.clients.list">
                </hia-el-select>
              </el-form-item>

              <center>
                <el-button-group style="margin-top: 10px;">
                  <el-button class="hiaButton" plain round type="text" size="mini" @click="closeNewRecordPopover">Cancel</el-button>
                  <el-button class="hiaButton" round :disabled="!addForm.patientNumber" size="mini" @click="addReview">Add </el-button>
                </el-button-group>
              </center>
            </el-form>

            <el-button class="hiaButton" style="margin-right: 10px;" slot="reference" :disabled="getSelectedRecords().length > 0 || (readOnly() && !projectManagement())" plain
              round size="mini" icon="el-icon-plus">New Record</el-button>
          </el-popover>

          <el-button :disabled="readOnly() && !projectManagement()" class="hiaButton" plain round v-if="getSelectedRecords().length > 0" size="mini"
            @click="showMassUpdateDialog = true">Mass Update </el-button>
          <el-button :disabled="readOnly() && !projectManagement()" class="hiaButton" plain round v-if="getSelectedRecords().length > 0" size="mini"
            @click="showMassDeleteDialog = true">Mass Delete </el-button>
          <el-button :disabled="readOnly() && !projectManagement()" class="hiaButton" plain round v-if="getSelectedRecords().length > 0" size="mini"
            @click="initNotifyCoders()">Notify Coders </el-button>
        </el-col>

        <el-col :span="10">
          <center class="stats">
            <el-badge class="item" :class="{ 'stats-loading': statsLoading }" :value="statistics.allRecords">
              <el-tag type="primary" size="small">All Records</el-tag>
            </el-badge>
            <el-badge class="item" :class="{ 'stats-loading': statsLoading }" :value="statistics.notStarted">
              <el-tag type="primary" size="small">Not Started</el-tag>
            </el-badge>
            <el-badge class="item" :class="{ 'stats-loading': statsLoading }" :value="statistics.inProgress">
              <el-tag type="primary" size="small">In Progress</el-tag>
            </el-badge>
            <el-badge class="item" :class="{ 'stats-loading': statsLoading }" :value="statistics.preliminary">
              <el-tag type="primary" size="small">Preliminary</el-tag>
            </el-badge>
            <el-badge class="item" :class="{ 'stats-loading': statsLoading }" :value="statistics.readyForCoder">
              <el-tag type="primary" size="small">Ready for Coder</el-tag>
            </el-badge>
            <el-badge class="item" :class="{ 'stats-loading': statsLoading }" :value="statistics.completed">
              <el-tag type="primary" size="small">Completed</el-tag>
            </el-badge>
          </center>
        </el-col>
        <el-col :span="7">
          <el-row :gutter="2">
            <el-col :span="24">
              <el-input size="mini" placeholder="filter all..." type="text" id="globalSearch" v-model="filterCriteria" v-on:keyup.native="runGlobalFilter($event)"> </el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <div class="margin-top-5">
        <el-popover ref="sortableColumns" placement="top" trigger="click" popper-class="sortableColumns" title="Edit Pro Columns">
          <el-form>
            <el-row>
              <div style="float: left; width: 150px; padding: 0px 5px 0px 5px;" v-for="s in sortableColumns.filter(f => f.key !== 'selected' && f.key !== 'delete')"
                :key="s.key">
                <el-form-item>
                  <input type="checkbox" :disabled="s.key === 'patientNumber'" v-model="s.selected" @click="handleSortableColumns(s)" /><span
                    style="font-size: 11px; line-height: 12px;">{{ s.title }}</span>
                </el-form-item>
              </div>
            </el-row>
          </el-form>
          <el-button-group style="float: right;">
            <el-button size="mini" type="primary" plain @click="resetSortableColumns()">Reset to Default</el-button>
            <el-button size="mini" type="primary" plain @click="hideSortableColumns()">Close</el-button>
          </el-button-group>
        </el-popover>
        <div style="margin: 0 auto; width: 30px;" ref="sortableColumnsRef"></div>

        <v-server-table v-if="sortableShow" ref="proReviewListGrid" id="proReviewListGrid" :columns="columns" :options="options" class="hiaTable" @loading="tableLoader = true"
          @loaded="tableLoader = false" v-loading="tableLoader" element-loading-spinner="atom-audit-loader">
          <template slot="selected" slot-scope="props">
            <input :disabled="readOnly() && !projectManagement()" v-model="props.row.checked" @click="selectRecord(props.row, $event)" type="checkbox" />
          </template>
          <template slot="patientNumber" slot-scope="props">
            <div>
              <!-- <router-link v-bind:to="$route.path + '/' + props.row.proRecordId">{{props.row.patientNumber}}</router-link> -->
              <router-link v-bind:to="'/reviews/pro/' + props.row.proRecordId"> {{ props.row.patientNumber ? props.row.patientNumber : 'No Patient Number' }}</router-link>
            </div>
          </template>
          <template slot="dateOfService" slot-scope="props">
            <div>
              {{ props.row.dateOfService ? formatDate(props.row.dateOfService) : '' }}
            </div>
          </template>
          <template slot="completeDate" slot-scope="props">
            <div>
              {{ props.row.completeDate ? formatDate(props.row.completeDate) : '' }}
            </div>
          </template>
          <template slot="reviewDate" slot-scope="props">
            <div>
              {{ props.row.reviewDate ? formatDate(props.row.reviewDate) : '' }}
            </div>
          </template>

          <template slot="recordTags" slot-scope="props">
            <template v-if="props.row.recordTags">
              <div class="el-tag el-tag--mini el-tag--light" style="max-width: 100px; " v-for="tag in props.row.recordTags.split(',')" :key="tag">
                <div :title="tag" style="overflow: hidden; text-overflow: ellipsis;">
                  {{ tag }}
                </div>
              </div>
            </template>
          </template>

          <template slot="level2ReasonForChange" slot-scope="props">
            <div>
              {{ props.row.reviewLevel !== 'Coding Review' ? props.row.level2ReasonForChange : '' }}
            </div>
          </template>
          <template slot="delete" slot-scope="props">
            <el-popover v-if="(readOnly() && projectManagement()) || !readOnly()" :ref="'delete_' + props.row.proRecordId" :popper-class="'delete_' + props.row.proRecordId"
              trigger="click" placement="left" width="190">
              <center>
                <p>Delete this Chart?</p>
                <!-- <el-button type="primary" size="mini" @click="deleteChart(props)">confirm</el-button> -->
                <el-button-group>
                  <el-button class="hiaButton" plain round type="text" size="mini" @click="deleteChart(props, 'delete_' + props.row.proRecordId, false)">Cancel</el-button>
                  <el-button class="hiaButton" plain round size="mini" @click="deleteChart(props, 'delete_' + props.row.proRecordId, true)">Confirm</el-button>
                </el-button-group>
              </center>
              <div slot="reference">
                <!-- <button :disabled="tableLoader" size="mini" type="button" title="Delete Chart" class="deleteButton" style="float: right;"></button> -->
                <div title="delete" style="text-align: center; cursor: pointer;">
                  <i class="el-icon-close delete-button"></i>
                </div>
              </div>
            </el-popover>
          </template>
          <template slot="rvuDiff" slot-scope="props">
            <span v-if="props.row.rvuDiff !== 0" :class="`${props.row.rvuDiff < 0 ? 'negative' : ''}`"> {{ props.row.rvuDiff }}</span>
          </template>
        </v-server-table>
      </div>
    </div>
    <div id="dialogs">
      <el-dialog :title="proListSelected.length > 0 ? 'Notify coders' : 'No Records Selected'" :visible.sync="showNotifyCoders">
        <v-client-table class="hiaTable" id="proReviewListNotifyCoders" ref="proReviewListNotifyCoders" :data="notifyCodersData"
          :columns="['coder', 'coderEmail', 'recordCount']" :options="notifyCodersOptions" element-loading-spinner="atom-audit-loader">
          <template slot="coder" slot-scope="props">
            {{ props.row.coder ? props.row.coder : '[not specified]' }}
          </template>
          <template slot="recordCount" slot-scope="props">
            {{ props.row.records.length }}
          </template>
          <template slot="child_row" slot-scope="props">
            <v-client-table style="width: 97%;float: right;" class="hiaTable" :data="props.row.records" :columns="['patientNumber', 'finalOutcome', 'agreeWithCodes']"
              :options="notifyCodersOptions" element-loading-spinner="atom-audit-loader">
              <template slot="patientNumber" slot-scope="props">
                <a href="" @click.stop.prevent="reviewListChildRowLink(props)">{{ props.row.patientNumber }}</a>
              </template>
            </v-client-table>
          </template>
          <template slot> </template>
        </v-client-table>

        <small>Notifications can only be sent to coders with email addresses</small>
        <span slot="footer">
          <el-button-group>
            <el-button @click="postNotifyCoders()" size="mini" plain round>Confirm</el-button>
            <el-button @click="showNotifyCoders = false" size="mini" plain round>Cancel</el-button>
          </el-button-group>
        </span>
      </el-dialog>

      <ProReviewsMassUpdate v-if="showMassUpdateDialog" @massupdate="handleMassUpdate" @close="showMassUpdateDialog = false" :showMassUpdateDialog="showMassUpdateDialog"
        :clients="dropdowns.clients.list" :selectedRecords="getSelectedRecords()" :selectedClients="getSelectedClients()"> </ProReviewsMassUpdate>

      <ReviewsMassDelete v-if="showMassDeleteDialog" @massDelete="runMassDelete" @close="closeMassDeleteDialog()" :idType="'proRecordId'"
        :showMassDeleteDialog="showMassDeleteDialog" :selectedRecords="proListSelected" :massDeleteData="massDeleteData" :massDeleteLoading="massDeleteLoading">
      </ReviewsMassDelete>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'

  import ProReviewsMassUpdate from '@/views/reviews/pro/ProReviewsMassUpdate'
  import ReviewsMassDelete from '@/components/controls/ReviewsMassDelete'

  import hiaTable from '@/mixins/hiaTable'
  import dateHelper from '@/mixins/date-helper'

  import Sortable from 'sortablejs'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import debounce from 'debounce'
  export default {
    name: 'proList',
    mixins: [dateHelper, hiaTable, HiaAuthorization],
    data() {
      return {
        showNotifyCoders: false,
        vueTables2Query: {},
        statsLoading: false,
        dropdownsNeeded: ['reviewLevels', 'clients', 'reviewStatus'],
        massDeleteData: {
          deleted: [],
          notDeleted: []
        },
        showMassDeleteDialog: false,
        massDelete: false,
        massDeleteLoading: false,
        validationsLoading: false,
        loading: true,
        isPosting: false,
        isActivated: false,
        showRecordStats: {
          show: false,
          recordId: 0
        },
        clients: [],
        selectedClientNumber: '',
        doubleClickPreventer: false,
        reviewDate: '',
        showAddReviewForm: false,
        showMassUpdateDialog: false,
        massupdateDropdowns: [],
        disableAddReview: true,
        copyToLevel2: {
          visible: false,
          // level2ReviewDate: null,
          projectID: 0,
          rules: {
            projectID: [{ required: true, message: 'Please set Project' }]
          }
        },
        addForm: {
          patientNumber: '',
          clientId: null
        },
        rules: {
          patientNumber: [{ required: true, message: 'Please set Patient Number', trigger: 'blur' }],
          clientId: [{ required: true, message: 'Please set Facility', trigger: 'none' }]
        },
        choices: false,
        filterCriteria: '',
        tableLoader: false,
        columns: ['selected', 'patientNumber', 'physicianName', 'numberDxCodes', 'numberEMCodes', 'numberCPTCodes', 'numberFindings', 'mrNumber', 'consultantNumber', 'recordTags', 'payorName', 'coder', 'reasonForChange', 'dateOfService', 'placeOfService', 'providerType', 'practiceName', 'patientName', 'reviewDate', 'status', 'auditResult', 'agreeWithCodes', 'finalOutcome', 'responseCount', 'comments', 'rvuDiff', 'delete'],
        options: {
          actionsPosition: 'right',
          requestFunction: data => {
            return this.requestData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            reviewLevel: []
          },
          sortable: ['patientNumber', 'physicianName', 'numberDxCodes', 'numberEMCodes', 'numberCPTCodes', 'numberFindings', 'mrNumber', 'consultantNumber', 'recordTags', 'payorName', 'coder', 'reasonForChange', 'dateOfService', 'placeOfService', 'providerType', 'practiceName', 'patientName', 'comments', 'status', 'auditResult', 'agreeWithCodes', 'finalOutcome', 'responseCount', 'reviewDate', 'rvuDiff'],
          orderBy: {
            column: 'patientNumber',
            ascending: true
          },
          columnsClasses: {
            selected: 'selected',
            recordTags: 'tagsCol',
            // excludeRecord: 'hideColumn',
            // ipreviewType: 'hideColumn',
            // subClientNumber: 'hideColumn',
            // originalReviewer: 'hideColumn',
            // originalReviewDate: 'hideColumn',
            // originalClientNumber: 'hideColumn',
            // reviewLevel: 'hideColumn',
            delete: 'delete'
          },
          headings: {
            selected: h => {
              return h('input', {
                attrs: {
                  type: 'checkbox',
                  id: 'selectAllCheckboxProList',
                  disabled: this.readOnly() && !this.projectManagement()
                },
                on: {
                  click: e => {
                    this.localSelectAll(e.srcElement.checked)
                  }
                },
                ref: 'selectAllCheckbox'
              })
            },
            patientNumber: 'Patient Number',
            consultantNumber: 'Consultant Number',
            payorName: 'Payor',
            mrNumber: 'MR Number',
            patientName: 'Patient Name',
            practiceName: 'Practice Name',
            providerType: 'Provider Type',
            recordTags: 'Record Tags',
            physicianName: 'Provider',
            dateOfService: 'Date of Service',
            reasonForChange: 'Reason F Change',
            level2ReasonForChange: 'Lvl 2 Reason F Change',
            numberEMCodes: '# EM Codes',
            numberCPTCodes: '# CPT Codes',
            numberDxCodes: '# Dx Codes',
            numberFindings: '# Findings',
            originalReviewer: 'Orig Reviewer',
            level2NumberFindings: '# Lvl 2 Findings',
            comments: 'Comments',
            placeOfService: 'Place of Service',
            completeDate: 'Complete Date',
            status: 'Review Status',
            reviewDate: 'Review Date',
            delete: '',
            agreeWithCodes: 'Coder Agrees',
            finalOutcome: 'Final Outcome',
            auditResult: 'Audit Result',
            responseCount: 'Response Count',
            rvuDiff: 'RVU Diff'
          },
          perPage: 25,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        thePROsortable: null,
        sortableShow: true,
        sortableColumns: [
          { key: 'selected', title: 'selected', selected: true },
          { key: 'patientNumber', title: 'Patient Number', selected: true },
          { key: 'physicianName', title: 'Provider', selected: true },
          { key: 'numberEMCodes', title: '# EM Codes', selected: true },
          { key: 'numberCPTCodes', title: '# CPT Codes', selected: true },
          { key: 'numberFindings', title: '# Compliance Findings', selected: true },
          { key: 'level2NumberFindings', title: '# Lvl 2 Findings', selected: true },
          { key: 'mrnumber', title: 'MR Number', selected: true },
          { key: 'consultantNumber', title: 'Consultant Number', selected: true },
          { key: 'payorName', title: 'Payor', selected: true },
          { key: 'coder', title: 'Coder', selected: true },
          { key: 'reasonForChange', title: 'Reason F Change', selected: true },
          { key: 'reviewLevel', title: 'Review Date', selected: true },
          { key: 'originalReviewer', title: 'Orig Reviewer', selected: true },
          { key: 'level2ReasonForChange', title: 'Lvl 2 Reason F Change', selected: true },
          { key: 'dateofService', title: 'Date of Service', selected: true },
          { key: 'placeOfService', title: 'Place of Service', selected: true },
          { key: 'providerType', title: 'Provider Type', selected: true },
          { key: 'practiceName', title: 'practiceName', selected: true },
          { key: 'patientName', title: 'Patient Name', selected: true },
          { key: 'completeDate', title: 'Complete Date', selected: true },
          { key: 'rebuttalCount', title: 'Rebuttal Count', selected: true },
          { key: 'rebuttalActiveCount', title: 'Active Rebuttals', selected: true },
          { key: 'rebuttalRespondedCount', title: 'Completed Rebuttals', selected: true },
          { key: 'recordStatus', title: 'Record Status', selected: true },
          { key: 'comments', title: 'Comments', selected: true },
          { key: 'numberDxCodes', title: '# Dx Codes', selected: true },
          { key: 'reviewDate', title: 'Review Date', selected: true },
          { key: 'rvuDiff', title: 'RVU Diff', selected: true },
          { key: 'delete', title: 'delete', selected: true }
        ],
        defaultColumns: [],
        notifyCodersColumns: ['patientNumber', 'coder', 'coderEmail', 'finalOutcome', 'agreeWithCodes'],
        notifyCodersOptions: {
          filterByColumn: true,
          listColumns: {
            reviewLevel: []
          },
          orderBy: {
            column: 'patientNumber',
            ascending: true
          },
          columnsClasses: {},
          headings: {
            patientNumber: 'Patient Number',
            agreeWithCodes: 'Coder Agrees',
            finalOutcome: 'Final Outcome',
            coderEmail: 'Email',
            coder: 'Coder',
            recordCount: 'Record Count'
          },
          perPage: 25,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' },
          uniqueKey: 'coderId'
          // columnsDropdown: true
        }
      }
    },
    methods: {
      ...mapActions('proReviews/', ['POST_VALIDATION', 'RUN_PRO_COPYTOLEVEL2', 'POST_PRO_RECORD', 'DELETE_PRO_RECORD', 'RUN_PRO_MASSDELETE', 'LOAD_PRO_LIST_PAGINATED', 'PRO_RECORD_EXISTS', 'GET_PRO_LIST_ALLIDS', 'GET_PROLIST_STATS', 'POST_NOTIFY_CODERS']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS', 'CONVERT_DROPDOWN_COLUMNFILTERARRAY']),
      ...mapMutations('proReviews/', ['SET_PROLIST_SELECTED', 'CLEAR_PROLIST_SELECTED', 'CLEAR_VALIDATION_LIST', 'SET_PROLIST_REFRESH_FUNC']),
      // ...mapActions('profile/', ['SET_PROFILE_COLUMNS']),
      async requestData(data) {
        try {
          data.projectID = this.$route.params.id
          data.globalSearch = this.filterCriteria
          this.vueTables2Query = data
          this.statsLoading = true
          const resultArray = await Promise.all([this.GET_PROLIST_STATS(data), this.LOAD_PRO_LIST_PAGINATED(data)])
          this.statsLoading = false
          return resultArray[1]
        } catch (err) {
          console.log(err)
        }
      },
      async localSelectAll(checked) {
        const table = this.$refs.proReviewListGrid.$el
        const checkboxes = table.querySelectorAll('input[type ="checkbox"]')

        if (checked) {
          this.tableLoader = true
          await this.GET_PRO_LIST_ALLIDS(this.vueTables2Query)
          for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = true
          }
          this.tableLoader = false
          // this.$refs.ipReviewListGrid.refresh()
        } else {
          this.CLEAR_PROLIST_SELECTED()
          for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false
          }
          // this.$refs.ipReviewListGrid.refresh()
        }
      },
      getSelectedRecords() {
        const simpleArray = this.proListSelected.map(y => y.proRecordId)
        return [...new Set(simpleArray)]
      },
      getSelectedClients() {
        const simpleArray = this.proListSelected.map(y => y.clientNumber)
        return [...new Set(simpleArray)]
      },
      selectRecord(row, e) {
        const payload = {
          checked: e.srcElement.checked,
          proRecordId: row.proRecordId,
          patientNumber: row.patientNumber,
          clientName: row.clientName,
          coder: row.coder,
          coderId: row.coderId,
          consultantNumber: row.consultantNumber,
          projectId: row.projectId,
          coderEmail: row.coderEmail
        }

        this.SET_PROLIST_SELECTED(payload)
      },
      handleMassUpdate() {
        const el = document.getElementById('selectAllCheckboxProList')
        if (el) {
          el.checked = false
        }
        this.showMassUpdateDialog = false
        this.localSelectAll(false) // clear all the selected checkboxes
        this.CLEAR_PROLIST_SELECTED()
        this.$refs.proReviewListGrid.getData()
      },
      toggleAddReviewForm() {
        if (this.showAddReviewForm) {
          this.$refs.addForm.clearValidate()
          this.showAddReviewForm = false
          this.addForm.patientNumber = ''
          this.addForm.clientId = null
        } else {
          this.showAddReviewForm = true
        }
      },
      addReview() {
        // use isPosting to prevent double clicks adding two records
        if (!this.isPosting) {
          this.$refs.addForm.validate(valid => {
            if (valid) {
              this.isPosting = true
              this.postproReview(this.addForm.patientNumber)
            } else {
              return false
            }
          })
        }
      },
      postproReview(patientNumber) {
        // const today = new Date()
        const newReview = {
          patientNumber: patientNumber.trim(),
          clientId: this.project.clientId ? this.project.clientId : this.addForm.clientId,
          reviewDate: this.project.startDate,
          projectID: this.projectPK
        }

        this.POST_PRO_RECORD(newReview).then(
          response => {
            // this.isPosting = false
            // this.$refs.addForm.clearValidate()
            // this.showAddReviewForm = false
            // this.addForm.patientNumber = ''
            this.$router.push({ path: `/reviews/pro/${response.proRecordId}` })
            this.$nextTick(() => {
              this.isPosting = false
              this.$refs.addForm.clearValidate()
              this.addForm.patientNumber = null
              this.addForm.clientId = null
            })
          },
          error => {
            console.log(error)
            this.isPosting = false
          }
        )
      },
      recordStatsActivate(condition, recordId) {
        this.showRecordStats = {
          show: condition,
          recordId: recordId
        }
      },
      deleteChart(props, ref, bool) {
        const popper = this.$refs[ref]
        popper.doDestroy()
        popper.doClose()
        if (!bool) {
          return false
        }

        this.tableLoader = true
        this.DELETE_PRO_RECORD(props.row.proRecordId).then(
          response => {
            this.$message({
              message: 'Chart Deleted Successfully',
              type: 'info'
            })
            this.$refs.proReviewListGrid.getData()
            this.tableLoader = false
          },
          error => {
            this.$message({
              message: error.response.data,
              type: 'error'
            })
            console.log(error)
            this.tableLoader = false
          }
        )
      },
      runMassDelete() {
        this.massDeleteLoading = true
        this.RUN_PRO_MASSDELETE(this.getSelectedRecords()).then(
          response => {
            this.massDeleteData = response.data
            const el = document.getElementById('selectAllCheckboxProList')
            if (el) {
              el.checked = false
            }
            this.CLEAR_PROLIST_SELECTED()
            this.$refs.proReviewListGrid.getData()
            this.massDeleteLoading = false
          },
          error => {
            this.closeMassDeleteDialog()
            this.$message({
              message: 'Error Mass Deleting Records',
              type: 'error'
            })
            console.log(error)
            this.massDeleteLoading = false
          }
        )
      },
      closeMassDeleteDialog() {
        this.showMassDeleteDialog = false
        this.massDeleteData = {
          deleted: [],
          notDeleted: []
        }
      },
      runGlobalFilter: debounce(function () {
        this.$refs.proReviewListGrid.getData()
      }, 400),
      attachSortable(parentElementId, onEndFn) {
        const gridDiv = document.getElementById(parentElementId)
        if (gridDiv) {
          const tbody = gridDiv.getElementsByTagName('thead')[0].getElementsByTagName('tr')[0]
          // add drag class to all except selected and delete
          for (let i = 0; i < tbody.getElementsByTagName('th').length; i++) {
            if (!tbody.getElementsByTagName('th')[i].classList.contains('selected') && !tbody.getElementsByTagName('th')[i].classList.contains('delete')) {
              tbody.getElementsByTagName('th')[i].classList.add('dragOn')
            }
          }
          const localSortable = Sortable.create(tbody, {
            animation: 200,
            draggable: 'th',
            handle: '.dragOn',
            onEnd: evt => {
              onEndFn(evt.oldIndex, evt.newIndex)
            }
          })
          this.thePROsortable = localSortable
        }
      },
      handleDragStop(oldIndex, newIndex) {
        const temp = [...this.columns]
        if (newIndex >= temp.length) {
          let j = newIndex - temp.length + 1
          while (j--) {
            temp.push(undefined)
          }
        }
        temp.splice(newIndex, 0, temp.splice(oldIndex, 1)[0])

        // update sortableColumns
        this.sortableColumns.sort((a, b) => {
          return temp.indexOf(a.key) - temp.indexOf(b.key)
        })

        this.columns = temp
        // Couldn't get both the columns and rows to match up so destroying grid/sortable and re-creating grid/sortable sigh...
        this.resetSortableGrid()
      },
      showSortableColumns() {
        const popper = this.$refs.sortableColumns
        popper.referenceElm = this.$refs.sortableColumnsRef
        popper.doShow()
      },
      hideSortableColumns() {
        const popper = this.$refs.sortableColumns
        popper.doClose()
      },
      handleSortableColumns(column) {
        setTimeout(() => {
          if (column.selected) {
            const columnIndex = this.sortableColumns.findIndex(f => f.key === column.key)
            if (columnIndex !== -1) {
              this.columns.splice(columnIndex, 0, column.key)
              this.resetSortableGrid()
            }
          } else {
            const columnIndex = this.columns.indexOf(column.key)
            if (columnIndex !== -1) {
              this.columns.splice(columnIndex, 1)
              this.resetSortableGrid()
            }
          }
        }, 5)
      },
      handleSortableColumnsUpdate() {
        const payload = {
          type: 'pro',
          userGuid: window.user.oid,
          proColumnList: JSON.parse(JSON.stringify(this.sortableColumns))
        }
        this.SET_PROFILE_COLUMNS(payload)
      },
      setUserProfileSortableColumns() {
        // if user profile columns exist set them otherwise use local
        // if (this.userProfile.profile !== null && this.userProfile.profile.proColumnList !== null && this.userProfile.profile.proColumnList !== undefined) {
        //   this.sortableColumns = JSON.parse(JSON.stringify(this.userProfile.profile.proColumnList))
        //   this.columns = this.sortableColumns.filter(f => f.selected).map(m => m.key)
        // } else {
        //   this.columns = this.sortableColumns.map(m => m.key)
        // }
      },
      resetSortableGrid() {
        this.thePROsortable.destroy()
        this.sortableShow = false
        this.$nextTick(() => {
          this.sortableShow = true
          this.$nextTick(() => {
            this.attachSortable('proReviewListGrid', this.handleDragStop)
            this.handleSortableColumnsUpdate()
          })
        })
      },
      resetSortableColumns() {
        const payload = {
          type: 'pro',
          userGuid: window.user.oid,
          proColumnList: JSON.parse(JSON.stringify(this.defaultColumns))
        }
        this.sortableColumns = JSON.parse(JSON.stringify(this.defaultColumns))
        this.columns = this.defaultColumns.filter(f => f.selected).map(m => m.key)
        this.SET_PROFILE_COLUMNS(payload)
        this.thePROsortable.destroy()
        this.sortableShow = false
        this.$nextTick(() => {
          this.sortableShow = true
          this.$nextTick(() => {
            this.attachSortable('proReviewListGrid', this.handleDragStop)
          })
        })
      },
      initNotifyCoders() {
        this.showNotifyCoders = !this.showNotifyCoders
      },
      async postNotifyCoders() {
        const notifyCodersData = []
        for (let i = 0; i < this.notifyCodersData.length; i++) {
          const c = this.notifyCodersData[i]
          if (c.coderEmail) {
            notifyCodersData.push({ coder: c.coder, auditor: c.auditor, coderEmail: c.coderEmail, recordCount: c.records.length, myRecordsUrl: `/my-projects/${this.projectPK}?type=Professional Fee` })
          }
        }

        try {
          this.showNotifyCoders = true
          await this.POST_NOTIFY_CODERS(notifyCodersData)
          this.showNotifyCoders = false
          this.$message({
            message: 'Coders Notification Successful',
            type: 'success'
          })
        } catch (err) {
          this.showNotifyCoders = false
          this.$message({
            message: err.message,
            type: 'error'
          })
        }
      },
      reviewListChildRowLink(props) {
        this.showNotifyCoders = !this.showNotifyCoders
        this.$router.push(`/reviews/pro/${props.row.proRecordId}`)
      },
      handleNewRecordEnter() {
        const el = document.getElementById('newPatientNumber')
        el.focus()
      },
      closeNewRecordPopover() {
        const popper = this.$refs.newRecordPopover
        if (popper) {
          popper.doDestroy()
          popper.doClose()
        }
      }
    },
    watch: {
      loading() {
        this.$nextTick(() => {
          this.attachSortable('proReviewListGrid', this.handleDragStop)
        })
      }
    },
    computed: {
      ...mapState('proReviews/', ['statistics', 'proListSelected', 'refreshProList']),
      ...mapState('managedLists/projects/', ['projectPK', 'project']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['user']),
      notifyCodersData() {
        if (this.proListSelected.length) {
          const groupedData = this.proListSelected.reduce((r, a) => {
            const found = r.find(f => {
              return f.coderId === a.coderId
            })
            if (found) {
              found.records.push(a)
            } else {
              r.push({ coderId: a.coderId, coder: a.coder, auditor: this.user.fullName, coderEmail: a.coderEmail, records: [a] })
            }
            return r
          }, [])
          return [...new Set(groupedData)]
        }
        return []
      }
    },
    created: function () {
      this.defaultColumns = JSON.parse(JSON.stringify(this.sortableColumns))
      this.loading = true
      Promise.all([this.GET_DROPDOWNS(this.dropdownsNeeded)]).then(() => {
        this.CONVERT_DROPDOWN_COLUMNFILTERARRAY('reviewLevels').then(response => {
          this.options.listColumns.reviewLevel = response
          this.loading = false
        })
        // this.setUserProfileSortableColumns()
      })

      const refreshFunc = () => {
        if (this.$refs.proReviewListGrid) {
          this.$refs.proReviewListGrid.getData()
        }
      }

      this.SET_PROLIST_REFRESH_FUNC(refreshFunc)
    },
    mounted: function () {
      // this.initialize()
    },
    activated: function () {
      this.refreshProList()
      this.isActivated = true
    },
    deactivated: function () {
      this.isActivated = false
      // clear any old validation messages, validation messages can be left around because the form validation gets triggered natively by
      // the browser when users hit the enter key in the form
      if (this.$refs.addForm) {
        this.$refs.addForm.clearValidate()
      }
      this.CLEAR_PROLIST_SELECTED()
      this.localSelectAll(false) // clear all the selected checkboxes
    },
    components: {
      ProReviewsMassUpdate,
      ReviewsMassDelete
    }
  }
</script>

<style scoped>
  >>>.tagsCol {
    max-width: 100px;
    overflow: hidden;
  }
</style>

<style>
  .my-multiselect .multiselect {
    background-color: blue;
  }

  #globalSearch {
    width: 300px;
    padding: 0px 0px 0px 10px;
    float: right;
  }

  .hideColumn {
    display: none;
  }

  .item {
    margin-top: 3px;
    margin-right: 35px;
  }

  .el-badge__content {
    background-color: #20a0ff;
  }

  input[name='vf__selected'] {
    display: none;
  }

  .stats-loading {
    opacity: 0.5;
  }

  .dragOn {
    cursor: move;
  }
</style>